import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FC, useEffect, useRef, useState } from 'react'

import CO2Label from '../../../components/co2Label/CO2Label'
import Spinner from '../../../components/spinner/Spinner'
import { DICTIONARY } from '../../../constants/dictionary'
import { useProfileContext } from '../../../context/ProfileContext'
import { useCancelShowroomQuote } from '../../../hooks/useCancelShowroomQuote'
import { useCurrency } from '../../../hooks/useCurrency'
import { useIntersection } from '../../../hooks/useIntersection'
import { useVehicleTileConfig } from '../../../hooks/useVehicleTileConfig'
import { handleEnter } from '../../../utils/handleEnter'
import { useShowroomQuote } from '../../../utils/reactQueryApi'
import { useShowroomConfig } from '../context/ShowroomContext'
import { MessageContent, MessageItem, MessageList } from './TileMessage.styled'
import { initState } from './util/vehicleTile'
import VehicleImage from './VehicleImage'
import VehicleInformation from './VehicleInformation'
import { ViewMode } from './VehicleList'
import {
  CO2Container,
  MonthlyPrice,
  VehicleCard,
  VehicleMonthlyPrice,
} from './VehicleList.styled'

export interface VehicleTileProps {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  quote: any
  viewMode: ViewMode
  animationOnHover: boolean
}

const VehicleTile: FC<VehicleTileProps> = ({
  quote,
  viewMode,
  animationOnHover = true,
}) => {
  const ref = useRef()
  const { locale } = useParams<{ locale: string }>()
  const showroomState = useShowroomConfig()
  const profile = useProfileContext()
  const { t } = useTranslation()
  const st = initState({
    showroomState,
    quoteSelected: useState<boolean>(false),
    showroomQuote: useShowroomQuote(locale, quote),
    props: { quote, viewMode, animationOnHover },
    isVisible: useIntersection(ref, '0px'),
    vehicleTitleConfig: useVehicleTileConfig(profile),
    actualQuotePrice: useState<boolean>(false),
    quotePrice: useState<number>(null),
    viewDisabled: useState<boolean>(false),
    policyValidationMessages: useState<string[]>([]),
    t,
  })
  const decimalPlaces = 2
  const currency = useCurrency(locale, st.currencyCode, decimalPlaces)
  const currencyWithoutDecimals = useCurrency(locale, st.currencyCode, 0, 0)
  useCancelShowroomQuote(
    showroomState.state.selectedQuoteIdentifier,
    quote.quotationIdentifier,
  )
  useEffect(() => st.recalculateQuoteEffect(), [st])
  useEffect(() => st.quoteEffect(), [st])
  useEffect(() => st.errorEffect(), [st])

  const vehicle = quote.vehicle

  const handleOpen = () => {
    !st.isViewDisabled() && st.handleVehicleSelection()
  }

  return (
    <VehicleCard
      ref={ref}
      data-testid="showroom-vehicle-card"
      viewMode={viewMode}
      animationOnHover={animationOnHover}
      onClick={(e) => {
        e.stopPropagation()
        handleOpen()
      }}
      viewDisabled={st.isViewDisabled().toString()}
      tabIndex={0}
      onKeyPress={(e) => {
        handleEnter(e.key, handleOpen)
      }}
    >
      <VehicleImage quote={quote} />
      {vehicle.efficiencyLabel && viewMode === 'grid' && (
        <CO2Container viewMode={viewMode}>
          <CO2Label
            co2Band={vehicle.efficiencyLabel.code}
            co2Value={vehicle.co2}
            showSelected={true}
          />
        </CO2Container>
      )}
      <VehicleInformation
        quote={quote}
        viewMode={viewMode}
        currencyWithoutDecimals={currencyWithoutDecimals}
        profile={profile}
      />
      {st.showMessageList() && (
        <MessageList>
          {st.getPolicyValidationMessages().map((message, index) => (
            <MessageItem key={'policy_validation_message' + index}>
              <WarningRoundedIcon />
              <MessageContent>{message}</MessageContent>
            </MessageItem>
          ))}
        </MessageList>
      )}

      {st.showVehicleMonthlyPrices() && (
        <VehicleMonthlyPrice viewMode={viewMode}>
          <div>{st.getStartingFromLabel()}</div>
          {!!st.getQuotePrice() && (
            <MonthlyPrice>
              <span>{currency?.format(st.getQuotePrice())}</span>&nbsp;
            </MonthlyPrice>
          )}
          {!st.getQuotePrice() && (
            <MonthlyPrice>
              <Spinner size={2} />
              &nbsp;
            </MonthlyPrice>
          )}
          <span>{t(DICTIONARY.PER_MONTH_LABEL)}</span>
          {st.showShowroomExclVat() && (
            <>
              &nbsp;
              <span>{t(DICTIONARY.VAT_EXCL)}</span>
            </>
          )}
        </VehicleMonthlyPrice>
      )}
    </VehicleCard>
  )
}

export default VehicleTile
