import { Button } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import styled from 'styled-components'

import VelocityButton from '../../../components/button/VelocityButton'
import { DICTIONARY } from '../../../constants/dictionary'
import { capitalize } from '../../../utils/string'
import { getStyles, isRebranding } from '../../../utils/styles'
import { useVehicleConfig } from '../context/vehicleConfigContext'

const RulesPopupFooter: FC = () => {
  const { t } = useTranslation()
  const { state, dispatch } = useVehicleConfig()
  return (
    <Root>
      <CancelButton
        variant="text"
        onClick={() => {
          dispatch({
            type: 'closeRulesPopupCanceled',
          })
        }}
      >
        {capitalize(t(DICTIONARY.CANCEL))}
      </CancelButton>
      {isRebranding() ? (
        <SubmitButton
          testId="RulesSubmitButton"
          disabled={!state.vehicleConfiguration?.valid}
          onClick={() => {
            dispatch({
              type: 'closeRulesPopupConfirmed',
            })
          }}
        >
          {capitalize(t(DICTIONARY.CONFIRM_SELECTION_TITLE))}
        </SubmitButton>
      ) : (
        <LeasePlanButton
          variant="contained"
          disabled={!state.vehicleConfiguration?.valid}
          onClick={() => {
            dispatch({
              type: 'closeRulesPopupConfirmed',
            })
          }}
        >
          {t(DICTIONARY.CONFIRM_SELECTION_TITLE)}
        </LeasePlanButton>
      )}
    </Root>
  )
}

const SubmitButton = styled(VelocityButton)``
const LeasePlanButton = styled(Button)`
  span {
    font-size: 0.9rem;
  }
`
const CancelButton = styled(Button)`
  color: ${getStyles().colors.configurator.rules.closeButton};
  margin: auto 29px auto 0;
  span {
    font-weight: normal;
    font-size: 0.9rem;
    text-transform: ${() => (isRebranding() ? 'none' : 'uppercase')};
  }
  &:focus {
    border: 1px solid #ccc;
  }
`
const Root = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
`

export default RulesPopupFooter
