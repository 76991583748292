import SaveIcon from '@material-ui/icons/Save'
import { ChevronRightIcon, ArrowIcon } from '@velocity/ui/draft'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FC } from 'react'
import styled from 'styled-components'

import VelocityButton from '../../../components/button/VelocityButton'
import { useProfileContext } from '../../../context/ProfileContext'
import { handleEnter } from '../../../utils/handleEnter'
import { capitalize } from '../../../utils/string'
import { isRebranding } from '../../../utils/styles'
import { useVehicleConfig } from '../context/vehicleConfigContext'
import {
  ContinueButtonRoot,
  DisabledSaveQuote,
  SaveQuote,
  ContinueButtonStyled,
} from './ContinueButton.styled'
import {
  continueButtonDisabled,
  getAddFavoritesLabel,
  getAddPolicyFavoritesLabel,
  getContinueButtonLabel,
  getSaveQuoteLabel,
  initState,
  onAddPolicyFavoritesClick,
  onContinueButtonClick,
  onSaveQuoteClick,
  onSaveQuoteWithDialogClick,
  showAddFavorites,
  showAddPolicyFavorites,
  showDisabledAddFavorites,
  showDisabledAddPolicyFavorites,
  showSaveQuote,
  showSaveQuoteWithDialog,
} from './util/continueButton'

interface Props {
  expanded?: boolean
}
const ContinueButton: FC<Props> = ({ expanded = true }) => {
  initState(
    useParams<{ product: string }>(),
    useVehicleConfig(),
    useSnackbar(),
    useTranslation(),
    useProfileContext(),
  )
  return (
    <ContinueButtonRoot expanded={expanded}>
      {isRebranding() ? (
        <VelocityButton
          testId="vehicleConfiguratorSubmitButton"
          disabled={continueButtonDisabled()}
          endIcon={<ArrowIcon size="s" color="white" />}
          onClick={onContinueButtonClick}
        >
          {capitalize(getContinueButtonLabel())}
        </VelocityButton>
      ) : (
        <ContinueButtonStyled
          data-e2e-id="vehicleConfiguratorSubmitButton"
          disabled={continueButtonDisabled()}
          variant="contained"
          endIcon={<ChevronRightIcon color="white" size="xs" />}
          onClick={onContinueButtonClick}
        >
          {getContinueButtonLabel()}
        </ContinueButtonStyled>
      )}
      {!showSaveQuote() && (
        <SaveQuoteSection>
          {showDisabledAddFavorites() && (
            <DisabledSaveQuote data-testid="disabled-save-btn">
              <SaveIcon />
              <div>{getAddFavoritesLabel()}</div>
            </DisabledSaveQuote>
          )}
          {showDisabledAddPolicyFavorites() && (
            <DisabledSaveQuote data-testid="disabled-save-policy-btn">
              <SaveIcon />
              <div>{getAddPolicyFavoritesLabel()}</div>
            </DisabledSaveQuote>
          )}
        </SaveQuoteSection>
      )}
      {showSaveQuote() && (
        <SaveQuoteSection>
          {showAddFavorites() && (
            <SaveQuote
              data-testid="save-btn"
              onClick={onSaveQuoteClick}
              tabIndex={0}
              onKeyPress={(e) => handleEnter(e.key, onSaveQuoteClick)}
            >
              <SaveIcon />
              <div>{getAddFavoritesLabel()}</div>
            </SaveQuote>
          )}
          {showAddPolicyFavorites() && (
            <SaveQuote
              data-testid="save-policy-btn"
              onClick={onAddPolicyFavoritesClick}
              tabIndex={0}
              onKeyPress={(e) => handleEnter(e.key, onAddPolicyFavoritesClick)}
            >
              <SaveIcon />
              <div>{getAddPolicyFavoritesLabel()}</div>
            </SaveQuote>
          )}
        </SaveQuoteSection>
      )}
      {showSaveQuoteWithDialog() && (
        <SaveQuote
          data-testid="save-btn"
          onClick={onSaveQuoteWithDialogClick}
          tabIndex={0}
          onKeyPress={(e) => handleEnter(e.key, onSaveQuoteWithDialogClick)}
        >
          <SaveIcon />
          <div>{getSaveQuoteLabel()}</div>
        </SaveQuote>
      )}
    </ContinueButtonRoot>
  )
}

const SaveQuoteSection = styled.div`
  display: flex;
  gap: 16px;
`

export default ContinueButton
