import { Backdrop, Drawer } from '@material-ui/core'
import { CloseIcon } from '@velocity/ui/draft'
import { FC } from 'react'
import styled from 'styled-components'

import { handleEnter } from '../../../utils/handleEnter'
import { getStyles } from '../../../utils/styles'
import { useVehicleConfig } from '../context/vehicleConfigContext'
import MoreInfoDrawerContent from './MoreInfoDrawerContent'

const s = () => getStyles().colors.configurator.moreInfo.drawer

const MoreInfoDrawer: FC = () => {
  const { state, dispatch } = useVehicleConfig()

  return (
    <>
      <DrawerStyled
        variant="persistent"
        anchor="left"
        open={state.moreInfoOpen}
      >
        <div>
          <CloseButton
            data-testid="more-info-top-close-button"
            size="xs"
            color={s().topCloseButton}
            onClick={() => {
              dispatch({
                type: 'updateMoreInfoOpen',
                moreInfoOpen: false,
              })
            }}
            tabIndex={0}
            onKeyPress={(e) =>
              handleEnter(e.key, () => {
                dispatch({
                  type: 'updateMoreInfoOpen',
                  moreInfoOpen: false,
                })
              })
            }
          />
          <MoreInfoDrawerContent />
        </div>
      </DrawerStyled>
      <BackdropStyled open={state.moreInfoOpen} />
    </>
  )
}

export const CloseButton = styled(CloseIcon)`
  margin-top: 0.5em;
  cursor: pointer;
  position: absolute;
  top: 7px;
  right: 18px;
  transform: scale(1.3);
  opacity: 0.3;

  &:focus,
  &:hover {
    border: 2px solid #000;
  }
`
const DrawerStyled = styled(Drawer)``
const BackdropStyled = styled(Backdrop)``

export default MoreInfoDrawer
