import { Tooltip, Checkbox } from '@velocity/ui'
import styled from 'styled-components'

import { getStyles } from '../../../utils/styles'

const s = () => getStyles().colors.configurator.optionCheckbox

export const StyledTooltip = styled(Tooltip)`
  [aria-describedby=mui-*]: {
    color: ${s().tooltip.font};
    background: ${s().tooltip.background};
  }
`
export const ExcludedBox = styled.button`
  height: 1.6rem;
  width: 1.7rem;
  background-color: ${s().exclude.background};
  cursor: not-allowed;
`
export const CheckboxContainer = styled.div`
  width: 3.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
export interface DisabledCheckboxProps {
  readonly $itemNotAllowed: boolean
  $isDisabled?: boolean
}

export const excludedCheckbox = { opacity: '0.4' }
export const disabledCheckbox = { opacity: '0.4', fontWeight: 800 }
export const selectedCheckbox = { opacity: '1', fontWeight: 800 }

export const StyledCheckboxAllowedEnabled = styled(Checkbox)`
  input:hover {
    cursor: 'pointer';
  }
  &:focus-within {
    border: 1px solid #000;
  }
  & > span {
    opacity: 1;
  }
`
export const StyledCheckboxAllowedDisabled = styled(Checkbox)`
  input:hover {
    cursor: 'pointer';
  }
  & > span {
    opacity: 0.4;
  }
`
export const StyledCheckboxNotAllowedDisabled = styled(Checkbox)`
  input:hover {
    cursor: 'not-allowed';
  }
  & > span {
    opacity: 0.4;
  }
`
export const StyledCheckbox = styled(Checkbox)<DisabledCheckboxProps>`
  input:hover {
    cursor: ${(props) => (props.$itemNotAllowed ? 'not-allowed' : 'pointer')};
  }
  & > span {
    opacity: ${(props) => (props.$isDisabled ? 0.4 : 1)};
  }
`
