import { CheckIcon } from '@velocity/ui/draft'
import { useTranslation } from 'react-i18next'
import { FC, useState, useEffect, useMemo } from 'react'

import { DICTIONARY } from '../../../constants/dictionary'
import { useProfileContext } from '../../../context/ProfileContext'
import { Option } from '../../../model/Quote'
import { handleEnter } from '../../../utils/handleEnter'
import {
  CheckboxSpacer,
  ItemContainer,
  ColorBox,
  ShowOptionsSwitch,
  OptionName,
  ChevronIconDown,
  ChevronIconUp,
} from './ColorItemWithOptions.styled'
import ColorOptionItem from './ColorOptionItem'

interface Props {
  option: Option | Option[]
  currency: Intl.NumberFormat
}

const ColorItemWithOptions: FC<Props> = ({ option, currency }) => {
  const profile = useProfileContext()
  const { t } = useTranslation()
  const [isShowOptions, setIsShowOptions] = useState(false)
  const [isShowItem, setIsShowItem] = useState(false)
  const [optionExpandCollapseLabel, setOptionExpandCollapseLabel] =
    useState<string>(t(DICTIONARY.SHOW_OPTIONS_LABEL))
  const optionValue = useMemo(
    () => (Array.isArray(option) ? option : [option]),
    [option],
  )
  useEffect(() => {
    if (optionValue[0]?.colour) {
      setIsShowItem(
        optionValue[0].colour.colourType.indexOf('Interior') > -1 ||
          (!optionValue[0].colour.colourType.includes('Exterior') &&
            !optionValue[0].colour.colourType.includes('Interior')),
      )
    }
  }, [optionValue])

  const onShowOptions = () => {
    setIsShowOptions((prev) => !prev)
    setOptionExpandCollapseLabel((prev) =>
      prev === t(DICTIONARY.HIDE_OPTIONS_LABEL)
        ? t(DICTIONARY.SHOW_OPTIONS_LABEL)
        : t(DICTIONARY.HIDE_OPTIONS_LABEL),
    )
  }

  function getOptionKey(o: Option) {
    return o.identifier || o.optionId
  }

  return (
    <>
      {isShowItem && !profile.user.blsConfig && (
        <>
          <ItemContainer>
            <ColorBox
              style={{ backgroundColor: optionValue[0]?.colour.colourHex }}
            >
              <CheckIcon size="xs" style={{ display: 'none' }} />
            </ColorBox>
            <CheckboxSpacer />
            <OptionName>{optionValue[0]?.colour.colourName}</OptionName>
            <ShowOptionsSwitch
              onClick={() => onShowOptions()}
              data-testid="color-option-switch"
              tabIndex={0}
              onKeyPress={(e) => handleEnter(e.key, () => onShowOptions())}
            >
              <div style={{ margin: '0 0.5em 0 auto' }}>
                {optionExpandCollapseLabel}
              </div>
              {!isShowOptions && <ChevronIconDown size="xs" />}
              {isShowOptions && <ChevronIconUp size="xs" />}
            </ShowOptionsSwitch>
          </ItemContainer>
          {isShowOptions &&
            optionValue
              .filter((a) => a.manufacturerName !== null)
              .sort((a, b) =>
                a.manufacturerName.localeCompare(b.manufacturerName),
              )
              .map((o: Option) => (
                <div key={getOptionKey(o)}>
                  <ColorOptionItem option={o} currency={currency} />
                </div>
              ))}
        </>
      )}
      {profile.user.blsConfig &&
        optionValue.map((o: Option) => (
          <div key={getOptionKey(o)}>
            <ColorOptionItem option={o} currency={currency} />
          </div>
        ))}
    </>
  )
}

export default ColorItemWithOptions
