import {
  Typography,
  Select,
  InputBase,
  MenuItem,
  Box,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { ReactElement } from 'react'
import styled from 'styled-components'

import { getStyles } from '../../../utils/styles'
import { useShowroomConfig } from '../context/ShowroomContext'

const s = () => getStyles().colors.showroom.vehicleList.perPage

const ItemsPerPageWrapper = styled.div`
  display: flex;
  align-items: center;

  & .MuiSelect-select.MuiSelect-select {
    font-weight: ${getStyles().fonts.body.weight};
  }
`
const ItemsPerPageLabel = styled(Typography)`
  color: ${s().label};
  margin-right: 10px;
`

const useStyles = makeStyles<Theme>(() => ({
  select: {
    backgroundColor: s().select.background,
    minWidth: '75px',
    boxSizing: 'border-box',
    padding: '12px 16px',
    border: `1px solid ${s().select.border}`,
    fontSize: '14px',
    '&:focus': {
      backgroundColor: s().select.focus,
      border: '2px solid #888',
    },
  },
  icon: {
    width: '20px',
    height: '27px',
    right: '10px',
  },
}))

const defaultItemsPerPageOptions = [12, 24, 36, 48, 60]
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const ItemsPerPage = (props: any): ReactElement => {
  const {
    labelItemsPerPage = 'Show',
    itemsPerPageOptions = defaultItemsPerPageOptions,
  } = props
  const { state, dispatch } = useShowroomConfig()
  const classes = useStyles()
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const onChangeItemsPerPage = (event: any) => {
    dispatch({ type: 'setItemsPerPage', itemsPerPage: +event.target.value })
    dispatch({ type: 'setActivePageNumber', activePageNumber: 0 })
  }
  return (
    itemsPerPageOptions.length > 1 && (
      <ItemsPerPageWrapper data-testid="ItemsPerPageWrapper">
        <ItemsPerPageLabel color="inherit" variant="body1">
          {labelItemsPerPage}
        </ItemsPerPageLabel>
        <Box mr={2}>
          <Select
            input={<InputBase />}
            value={state.itemsPerPage}
            onChange={onChangeItemsPerPage}
            inputProps={{ 'aria-label': labelItemsPerPage }}
            IconComponent={KeyboardArrowDown}
            classes={classes}
            data-testid="ItemsPerPageSelect"
          >
            {itemsPerPageOptions.map((itemsPerPageOption: number) => (
              <MenuItem key={itemsPerPageOption} value={itemsPerPageOption}>
                {itemsPerPageOption}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </ItemsPerPageWrapper>
    )
  )
}
