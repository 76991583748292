import { CloseIcon } from '@velocity/ui/draft'
import styled from 'styled-components'

import { getStyles } from '../../../utils/styles'

const s = () => getStyles().colors.configurator.rules

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${s().font};
  padding: 0.7em;
  position: relative;
`

export const CloseButton = styled(CloseIcon)`
  cursor: pointer;
  position: absolute;
  top: 7px;
  right: 18px;
  transform: scale(1.3);
  opacity: 0.3;
  :focus {
    border: 1px solid #ccc;
  }
`
