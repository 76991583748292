import React, { FC } from 'react'

import { handleEnter } from '../../../utils/handleEnter'
import { getStyles } from '../../../utils/styles'
import { useVehicleConfig } from '../context/vehicleConfigContext'
import { CloseButton, RootContainer } from './RulesPopup.styled'
import RulesPopupFooter from './RulesPopupFooter'
import { RulesItemProps } from './RulesPopupItem'

export interface OptionRulesModel {
  optionId: number
  component: React.FunctionComponentElement<RulesItemProps>
}

const RulesPopup: FC = () => {
  const { state, dispatch } = useVehicleConfig()

  return (
    <RootContainer>
      <CloseButton
        size="xs"
        color={getStyles().colors.configurator.rules.closeButton}
        onClick={() => {
          dispatch({
            type: 'closeRulesPopupCanceled',
          })
        }}
        tabIndex={0}
        onKeyPress={(e) =>
          handleEnter(e.key, () =>
            dispatch({
              type: 'closeRulesPopupCanceled',
            }),
          )
        }
      />

      {state.rules.map((e: OptionRulesModel, index: number) =>
        React.cloneElement(e.component, {
          key: index,
        }),
      )}

      <RulesPopupFooter />
    </RootContainer>
  )
}

export default RulesPopup
