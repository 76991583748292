import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import styled from 'styled-components'

import { getStyles } from '../../utils/styles'

const s = () => getStyles().colors.configurator

export const AccordionStyled = styled(Accordion)`
  .MuiAccordionSummary-root {
    min-height: 60px;
  }

  .MuiAccordionSummary-content.Mui-expanded,
  &.Mui-expanded {
    margin: 0;
  }

  &:before {
    background: ${s().accordionSummary.before.background};
  }

  &.Mui-expanded:before {
    opacity: 1;
  }
`

export const AccordionSummaryStyled = styled(AccordionSummary)`
  background: ${s().accordionSummary.background};
  font-weight: bold;
  color: ${s().accordionSummary.font};

  &:hover,
  &:focus {
    background: ${s().accordionSummary.hover.background};
  }
`

export const AccordionDetailsStyled = styled(AccordionDetails)`
  flex-direction: column;
  padding: 0;
  background: ${s().accordionSummary.details.background};
`
export const Root = styled(Grid)`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
export const MainContainer = styled(Grid)`
  height: calc(100vh - 50px);
  width: 60%;
  background-color: ${s().mainContainer.background};
  user-select: none;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`
export const SideContainerStyled = styled(Grid)`
  height: calc(100vh - 50px);
  width: 40%;
  overflow: auto;
  background: ${s().sideContainer.background};
  @media (max-width: 768px) {
    width: 100%;
  }
`
export const SpinnerBox = styled(Box)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
`

export const CloseIconStyled = styled(CloseIcon)`
  color: ${s().closeIcon.font};
`

export const MoreInfoBlurBackground = styled.div`
  position: absolute;
  background: ${s().moreInfo.background};
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  z-index: 1101;
`
