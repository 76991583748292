import { Button } from '@velocity/ui/draft'
import styled from 'styled-components'

import { getStyles, isRebranding } from '../../../utils/styles'

const s = () => getStyles().colors.configurator.priceBreakdown.context

export const SelectedConfigurationTitle = styled.h2`
  font-weight: ${getStyles().fonts.title.weight};
`

export const PriceBreakdownRoot = styled.div`
  overflow: auto;
  padding: 0 1rem;
  height: 49vh;
  @media (max-width: 768px) {
    height: auto;
    overflow: none;
  }
  h1 {
    font-size: 1.7rem;
  }
  h2 {
    font-size: 1rem;
    margin-bottom: 0;
  }
  hr {
    background: ${s().separator};
    opacity: 0.2;
  }
`
export const ContinueButtonStyled = styled.div`
  margin-bottom: 1.8rem;
  > div {
    position: initial;
  }
`
export const ContractDuration = styled.div`
  display: flex;
  justify-content: space-between;
  text-transform: ${() => (isRebranding() ? 'none' : 'uppercase')};
  font-size: 0.7rem;
  font-weight: 100;
  margin-bottom: 1.5rem;
`
export const Notice = styled.p`
  font-size: 12px;
  font-weight: 300;
  background: ${s().notice.background};
  border: 1px solid ${s().notice.border};
  color: ${s().notice.font};
  padding: 1em;
  margin: 1em;
`
export const Item = styled.div`
  display: flex;
  justify-content: space-between;

  div {
    font-size: 1rem;
    font-weight: ${getStyles().fonts.title.weight};
  }
`
export const TotalItem = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    margin: 0;
    font-weight: ${getStyles().fonts.title.weight};
  }
  span {
    font-weight: ${getStyles().fonts.title.weight};
  }
  div {
    h1 {
      display: inline-block;
    }
    span {
      font-size: 0.8rem;
    }
  }
`
export const SelectedOptions = styled.ul`
  padding-left: 1em;
  margin-bottom: 0;
  margin-top: 0;
`
export const OptionItem = styled.li`
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  font-size: 0.8rem;
  opacity: 0.8;
`
export const RemoveAll = styled(Button)`
  color: ${s().removeAllButton.font};
  svg {
    color: ${s().removeAllButton.font};
  }
  display: flex;
  padding-top: 0;
  span > span {
    margin-left: 0.3em;
    font-weight: ${s().removeAllButton.weight};
  }
  :focus {
    text-decoration: underline;
  }
  svg {
    transform: scale(0.8);
  }
`
export const ShowAll = styled(Button)`
  color: ${s().showAllButton.font};
  font-weight: 600;
  padding-left: 0;
  span {
    font-size: 1rem;
  }
  :focus {
    text-decoration: underline;
  }
`
