import { FC } from 'react'
import styled, { css } from 'styled-components'

import leftArrowOn from './images/left-arrow-on.png'
import leftArrow from './images/left-arrow.png'
import rightArrowOn from './images/right-arrow-on.png'
import rightArrow from './images/right-arrow.png'
import {
  ButtonOnType,
  initState,
  isLeftOn,
  isRightOn,
} from './util/navigations'

interface Props {
  rotateDirection: ButtonOnType
  isDragging: boolean
}
const Navigations: FC<Props> = ({ rotateDirection, isDragging }) => {
  initState(rotateDirection, isDragging)
  return (
    <Root>
      <RotateRight data-testid="rotate-right-btn" tabIndex={0}>
        {isLeftOn() ? (
          <img src={leftArrowOn} alt="leftArrowOn" />
        ) : (
          <img src={leftArrow} alt="leftArrow" />
        )}
      </RotateRight>
      <RotateLeft data-testid="rotate-left-btn" tabIndex={0}>
        {isRightOn() ? (
          <img src={rightArrowOn} alt="rightArrowOn" />
        ) : (
          <img src={rightArrow} alt="rightArrow" />
        )}
      </RotateLeft>
    </Root>
  )
}

const rotationIconContainerStyles = css`
  position: relative;
  bottom: 36%;
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    bottom: 75%;
  }
`

const navigationButtonStyles = css`
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  img {
    width: 200px;
  }
  @media (max-width: 768px) {
    img {
      width: calc(100vw / 3 - 40px);
    }
  }
`

const Root = styled.div`
  ${rotationIconContainerStyles}
  z-index: 1;
`

const RotateLeft = styled.button`
  ${navigationButtonStyles}
`

const RotateRight = styled.button`
  ${navigationButtonStyles}
`

export default Navigations
